import React from 'react'

interface Props {
    goToNextPage: () => void;
}

export default function NextPageButton({ goToNextPage }: Props) {
    return (
        <li className="page-item">
            <div onClick={() => goToNextPage()} className="pointer page-link pageLinkUnclicked  border border-1">
                next
            </div>
        </li>
    )
}