import { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

interface Props {
    searchDocumentAndSelectExamType: (docType: string) => void;
}

export default function DocumentEntryInput({ searchDocumentAndSelectExamType }: Props) {

    const [examEntryInput, setInput] = useState("");


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        
        let valUpper = value.toUpperCase();
        if (valUpper === "M" || valUpper === "O" || valUpper === "A") {
            setInput(valUpper)
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (examEntryInput === "M" || examEntryInput === "O" || examEntryInput === "A") {
                searchDocumentAndSelectExamType(examEntryInput);
            }
        } else if (event.key === "Backspace") {
            setInput("");
        }
    }


    return (
        <div className="mb-0">
            <InputGroup>
                <FormControl
                    data-testid="documentEntryInput"
                    placeholder=""
                    aria-label="Enter ssn"
                    aria-describedby="basic-addon2"
                    autoFocus={true}
                    value={examEntryInput}
                    name="input"
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(event)}
                    maxLength={1}
                />
                <Button
                    id="basic-addon2"
                    className="btn bg-dark-blue"
                    onClick={() => searchDocumentAndSelectExamType(examEntryInput)}
                >
                    select doc
                </Button>
            </InputGroup>
            <Form.Text>Options: M, O, A</Form.Text>
        </div>
    )
}