import { ExamDocumentInterface } from '../../helpers/interfaces';
import { SelectDocumentFunction } from '../../pages/ExamEntry';
import ExamDocument from '../ExamDocument/ExamDocument';

interface Props {
    examDocs: ExamDocumentInterface[];
    chosenDocument: ExamDocumentInterface;
    selectDocument: SelectDocumentFunction;
}

export default function DocumentQueue({ examDocs, chosenDocument, selectDocument }: Props) {

    return (
        <div>
            <h5 data-testid="documentQueueHeader">Document Queue</h5>
            <div className="bg-light rounded" style={{ minHeight: "40vh" }}>
                <div className="form-text text-muted" data-testid="docsLength">{examDocs.length} documents total</div>

                <div className="mx-1" style={{ maxHeight: "600px", overflow: "auto" }}>
                    {examDocs.map((doc: ExamDocumentInterface, index: number) => (
                        <ExamDocument
                            key={doc.id}
                            doc={doc}
                            selectDocument={selectDocument}
                            chosenDocument={chosenDocument}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}