import { useEffect, useState } from "react";
import { Switch, Route } from 'react-router-dom';
import ExamEntry from './pages/ExamEntry';
import API from './utils/API';
import './App.css';

const pino = require('pino');


export interface EnvironmentSettings {
  backendApiUrl: string;
  logLevel: string;
}

function App() {

  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logger, setLogger] = useState<any>();


  const [environmentSettings, setEnvironmentSettings] = useState<EnvironmentSettings>({
    backendApiUrl: "",
    logLevel: ""
  })


  useEffect(() => {

    setLoading(true);
    API.getEnvironment()
      .then(res => {
        let pinoLogger = pino({
          level: res.data.logLevel || "silent"
        })
        let logLevel = res.data.logLevel;
        if (logLevel !== "silent" && logLevel !== "") {
          console.log("azure environment is: ", res.data);
        }

        setEnvironmentSettings(res.data);
        setLogger(pinoLogger);

        setLoaded(true);
        setError(false);
        setLoading(false);
      })
      .catch(err => {
        setError(true);
        setLoaded(true);
        setLoading(false);
        console.log("azure environment data not found: ", err);
      })


  }, []);


  return (
    <div className="App">


      {loading && <h1>loading...</h1>}

      <Switch>

        <Route exact path="/" render={() => <h1>Not found</h1>} />
        <Route exact path="/:userName" render={() => loaded && <ExamEntry logger={logger} environmentSettings={environmentSettings} />} />
        <Route path="*" render={() => <h1>Page not found</h1>} />

      </Switch>


      {error && <div className="p-3 redBadge">Error</div>}

    </div>
  );
}

export default App;