
interface Props {
    setPostsPerPage: (posts: number) => void;
}

export default function SelectPageCount({setPostsPerPage }: Props) {
    return (
        <select className="form-select" onChange={e => setPostsPerPage(parseInt(e.target.value))}>
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>24</option>
            <option>48</option>
        </select>
    )
}