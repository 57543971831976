import { Spinner } from "react-bootstrap";

interface Props {
    spinnerColor?: string;
    text?: string;
    textColor?: string;
}

export default function LoadingSpinner({ spinnerColor, text, textColor }: Props) {
    return (
        <div>
            <Spinner data-testid="loadingSpinner" animation="border" variant={spinnerColor ? spinnerColor : "primary"} size="sm" /> <span data-testid="spinnerText" className={textColor ? textColor : "text-muted"}>{text || "loading"}...</span>
        </div>
    )
}