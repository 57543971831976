import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { formatSSN } from '../../helpers/functions';
import ExamEntryInput from '../DocumentEntryInput/DocumentEntryInput';
import CheckSsnInput from '../CheckSsnInput/CheckSsnInput';
import { ExamDocumentInterface } from '../../helpers/interfaces';
import ExamDateInput from '../ExamDateInput/ExamDateInput';
import ApplicantInfo from '../ApplicantInfo/ApplicantInfo';
import SearchResultsTable from '../SearchResultsTable/SearchResultsTable';
import ViewWholeDocument from '../ViewWholeDocument/ViewWholeDocument';
import { Spinner } from 'react-bootstrap';

interface Props {
  updateSSN: (ssn: string) => void;
  ssnIsValid: boolean;
  checkSSN: () => void;
  checkingSSN: boolean;
  ssnCheckButtonClicked: boolean;
  isApplicantDataLoaded: boolean;
  loadingApplicantData: boolean;

  applicantData: any;

  chosenDocument: ExamDocumentInterface;
  searchDocumentAndSelectExamType: any;
  showExamInput: boolean;
  examSearchResults: any;
  goToSignatureReviewButtons: () => void;
  showWholeDocument: boolean;
  setShowWholeDocument: (show: boolean) => void;

}

export default function DataEntrySection({
  updateSSN,
  ssnIsValid,
  checkSSN,
  checkingSSN,
  ssnCheckButtonClicked,
  isApplicantDataLoaded,
  loadingApplicantData,
  applicantData,
  chosenDocument,
  searchDocumentAndSelectExamType,
  showExamInput,
  examSearchResults,
  goToSignatureReviewButtons,
  showWholeDocument,
  setShowWholeDocument
}: Props) {


  const [docHasErrors] = useState(false);

  const noDocumentIsChosen = !chosenDocument.id || !chosenDocument.type || chosenDocument.fileSize === 0;



  const moveToSignatureReview = () => {
    setShowWholeDocument(true);
    goToSignatureReviewButtons();
  }



  return (
    <div className="bg-light text-light p-2 pt-0 rounded">
      <div className="d-flex justify-content-between">

        <div>
        </div>

        <div style={{ display: "none" }}>
          {docHasErrors}
        </div>

      </div>

      <Row>
        <Col md={6} lg={5} className="mt-3">

          <div className="d-flex justify-content-between">

            <CheckSsnInput
              checkSSN={checkSSN}
              formatSSN={formatSSN}
              updateSSN={updateSSN}
              disabled={noDocumentIsChosen}
            />

            <div>

            </div>
          </div>

        </Col>
        <Col>
          {ssnCheckButtonClicked && !checkingSSN && ssnIsValid &&
            <div className="greenBadge mt-3 rounded p-2">✔ SSN found</div>
          }
        </Col>
      </Row>

      {checkingSSN &&
        <div className="">
          <Spinner animation="border" variant="primary" size="sm" /> <span className="text-muted">Checking ssn...</span>
        </div>
      }


      {ssnCheckButtonClicked && !checkingSSN &&
        <>
          {ssnIsValid &&
            <div className="greenBadge p-3">

              {loadingApplicantData && <p>Loading...</p>}


              {isApplicantDataLoaded && !loadingApplicantData &&
                <div className="text-dark mt-2">

                  <Row>
                    <Col md={6} lg={4}>
                      <ExamEntryInput searchDocumentAndSelectExamType={searchDocumentAndSelectExamType} />
                    </Col>
                    <Col>
                      <ApplicantInfo applicantData={applicantData} />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={4}>
                      {showExamInput &&
                        <div className="mb-2">
                          <label>Exam Date</label>
                          <ExamDateInput
                            defaultDate={examSearchResults[0].examDate}
                            handleClick={() => moveToSignatureReview()}
                          />
                          <div className="form-text text-muted">If ok, press enter to continue</div>
                        </div>
                      }
                    </Col>

                    <Col>
                      <div>
                        {examSearchResults.map((exam: any) => (
                          <SearchResultsTable key={exam.id} exam={exam} />
                        ))}
                      </div>

                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div>
                        {showWholeDocument &&
                          <ViewWholeDocument show={showWholeDocument} />
                        }
                      </div>
                    </Col>
                  </Row>

                </div>
              }

            </div>
          }

          {!ssnIsValid &&
            <div className="redBadge p-3 rounded">
              SSN not found
            </div>
          }

        </>
      }

    </div >
  )
}