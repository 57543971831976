import { useRef } from 'react';
import { getDate, getMonth, getYear } from "../../helpers/functions";

interface Props {
  defaultDate: any;
  handleClick: () => void;
}

export default function ExamDateInput({ defaultDate, handleClick }: Props) {

  
  const inputRef = useRef<HTMLInputElement>(null);

  const month = getMonth(defaultDate);
  const day = getDate(defaultDate);
  const year = getYear(defaultDate);
  const monthAdjusted = month < 10 ? "0" + month.toString() : month;
  const formattedDefaultDate = `${year}-${monthAdjusted}-${day}`;


  // =======================================================================================
  // =======================================================================================

  // const [examEntryInput, setInput] = useState("");

 


  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleClick()
    }
  }

  // =======================================================================================
  // =======================================================================================


  return (
    <>
      <input
        type="date"
        data-testid="examDateInput"
        ref={inputRef}
        id="examDateInput"
        defaultValue={formattedDefaultDate}
        className="form-control" autoFocus={true}
        tabIndex={0}
        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(event)}
      />
    </>
  )
}