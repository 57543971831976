import { useEffect, useState } from 'react';
import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { Buffer } from 'buffer';
import DocumentQueue from '../components/DocumentQueue/DocumentQueue';
import DataEntrySection from "../components/DataEntrySection/DataEntrySection";
import EntryHistoryTable from "../components/EntryHistoryTable/EntryHistoryTable";
import { examDiscardedDocs, examDocs, examHeldDocs, validSsnList, exampleApplicants, exampleExamDocs } from '../data/data';
import { ExamDocumentInterface } from '../helpers/interfaces';
import API from '../utils/API';
import SignatureQuestions from '../components/SignatureQuestions/SignatureQuestions';
import SignatureReviewBtn from '../components/SignatureReviewBtn/SignatureReviewBtn';
import Pagination from '../components/Pagination/Pagination';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import { EnvironmentSettings } from '../App';
import SelectPageCount from '../components/SelectPageCount/SelectPageCount';


export interface SelectDocumentFunction {
    (arg: ExamDocumentInterface): void;
}

interface Props {
    logger: any;
    environmentSettings: EnvironmentSettings;
}

type Param = {
    userName: string;
}


export default function ExamEntry({ logger, environmentSettings }: Props) {

    let { backendApiUrl } = environmentSettings;

    let { userName } = useParams<Param>();



    const [userNameDecoded, setUserNameDecoded] = useState("");

    logger.info(userNameDecoded)


    const [loadingDocs, setLoadingDocs] = useState(false);
    const [docsLoaded, setDocsLoaded] = useState(false);
    const [loadingError, setLoadingError] = useState(false);


    const [holdingDoc, setHoldingDoc] = useState(false);
    const [discardingDoc, setDiscardingDoc] = useState(false);
    const [savingDoc, setSavingDoc] = useState(false);

    const [ssn, setSSN] = useState("");

    const [checkingSSN, setCheckingSSN] = useState(false);
    const [ssnIsValid, setSsnIsValid] = useState(false);
    const [ssnCheckButtonClicked, setSSNCheckButtonClicked] = useState(false);


    const [applicantData, setApplicantData] = useState<any>(null);
    const [isApplicantDataLoaded, setApplicantDataLoaded] = useState(false);
    const [loadingApplicantData, setLoadingApplicantData] = useState(false);

    const [showExamInput, setShowExamInput] = useState(false);

    const [examSearchResults, setExamSearchResults] = useState<any[]>([]);


    const [showWholeDocument, setShowWholeDocument] = useState(false);
    const [examEntryInput, setExamEntryInput] = useState("");



    const [chosenDocument, setChosenDocument] = useState<ExamDocumentInterface>({
        id: "",
        type: "",
        fileSize: 0,
        pages: 0,
        examDate: undefined,
        fileType: "",
        received: "",
        receivedFrom: ""
    })




    const [foundDocs, setFoundDocs] = useState<any[]>([]);

    const [showSignatureReviewBtns, setShowSignatureReviewBtns] = useState(false);



    // ===================== Pagination ================================
    // ===================== Pagination ================================

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingCurrentPage, setLoadingCurrentPage] = useState(false);
    const [postsPerPage, setPostsPerPage] = useState(2);


    // get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentResults = examDocs.slice(indexOfFirstPost, indexOfLastPost);


    // change page
    function paginate(pageNumber: number) {
        // on each page change load new results
        setLoadingCurrentPage(true);
        simulateNetworkRequest()
            .then(() => {
                setLoadingCurrentPage(false);
            })
            .catch(err => {
                logger.error(err);
                setLoadingCurrentPage(false);
            })

        setCurrentPage(pageNumber);
    }

    // ===================== End Pagination ================================
    // ===================== End Pagination ================================


    const selectDocument = (document: ExamDocumentInterface) => {
        setChosenDocument(document);
        clearDocumentMetaState();

    };

    const clearDocumentMetaState = () => {
        setSSNCheckButtonClicked(false);
        setSsnIsValid(false);
        setSSN("");
        setFoundDocs([]);
        setShowSignatureReviewBtns(false);
        setShowWholeDocument(false);
        setShowExamInput(false);


        let ssnInputField = document!.getElementById("checkSsnInput") as HTMLInputElement;
        if (ssnInputField !== null) {
            ssnInputField.value = ""
            ssnInputField.focus();
        }
    }

    const simulateNetworkRequest = () => {
        return new Promise(resolve => setTimeout(resolve, 500))
    }



    const checkSSN = () => {

        setSSNCheckButtonClicked(true);
        setCheckingSSN(true);


        simulateNetworkRequest().then(() => {

            let applicantsToSearchThrough = exampleApplicants;

            if (validSsnList.includes(ssn)) {
                logger.info("ssn is valid")
                setCheckingSSN(false);
                setSsnIsValid(true);

                let foundApplicant = applicantsToSearchThrough.find(applicant => applicant.ssn === ssn);
                logger.info("found applicant is: ", foundApplicant);

                setApplicantData(foundApplicant);
            } else {
                logger.error("ssn is NOT valid")
                setCheckingSSN(false);
                setSsnIsValid(false);
                return
            }

            logger.info("loading applicant data...")
            // setLoadingApplicantData(true);
            // API.getApplicantData()
            //     .then(() => {
            logger.info("finished loading applicant data");
            setApplicantDataLoaded(true);
            setLoadingApplicantData(false);

            setFoundDocs(exampleExamDocs);
            setExamSearchResults(exampleExamDocs);

            // })
            // .catch(err => {
            //     logger.error(err)
            // })
        })
    }


    const searchDocs = (searchInput: string) => {
        let search = searchInput === "M" ? "Medical" : searchInput === "A" ? "Audiological" : searchInput === "O" ? "Optometric" : searchInput === "History" ? "H" : "";
        let searchResults = foundDocs.filter(item => item.name === search);
        // logger.info(searchResults);
        setExamSearchResults(searchResults);
    }


    const getErrorTypes = (errorTypeLetter: string) => {
        let reviewLevel = "0"; // review level is always 0 for this app
        let docType = errorTypeLetter;

        API.getErrorTypesByReviewLevel(backendApiUrl, reviewLevel, docType)
            .then(res => {
                logger.info("get error types res: ", res);
                setErrorTypesByReviewLevel(res.data);
            })
            .catch(err => {
                logger.error("error getting error types: ", err);
            })
    }


    const updateSSN = (ssn: string) => {
        // logger.info("updatessn function ssn input: ", ssn);
        // let formattedSSN = formatSSN(ssn);
        // logger.info("formatted ssn : ", formattedSSN);
        setSSN(ssn);
    }


    // search doc based on exam input type ("M", "H", "O", "A")
    const searchDocumentAndSelectExamType = (letter: string) => {
        if (letter === "M" || letter === "O" || letter === "A") {
            searchDocs(letter);
            // return the error types (the signature questions) by review level (fixed at level 0 bc exam entry is level 0) from APIx;
            getErrorTypes(letter);
            setShowExamInput(true);
            setExamEntryInput(letter);
        }
    }



    const goToSignatureReviewButtons = () => {
        setShowSignatureReviewBtns(true);
        // focusOnFirstSignatureQuestion();
    }




    const clear = () => {
        setSsnIsValid(false);
        setShowWholeDocument(false);
        setSSNCheckButtonClicked(false);
    }

    const holdDoc = () => {
        setHoldingDoc(true);

        simulateNetworkRequest().then(() => {
            setHoldingDoc(false);
            clear();
        })
    }

    const discardDoc = () => {
        setDiscardingDoc(true);

        simulateNetworkRequest().then(() => {
            setDiscardingDoc(false);
            clear();
        })
    }

    const saveDoc = () => {
        setSavingDoc(true);

        simulateNetworkRequest().then(() => {
            setSavingDoc(false);
            clear();
        })
    }



    useEffect(() => {
        setLoadingDocs(true);

        // take the url param as a base64EncodedString and decode it into plain text
        logger.info('userName: ', userName);
        let userNameDecoded = (Buffer.from(userName as string, 'base64')).toString();
        logger.info('userName decoded: ', userNameDecoded);
        setUserNameDecoded(userNameDecoded);



        API.getQueue()
            .then(() => {
                // logger.info("docs retrieved from API")
                setDocsLoaded(true);
                setLoadingDocs(false);
                setLoadingError(false);
                setShowView(true);
            })
            .catch(err => {
                // logger.info(err);
                setDocsLoaded(true);
                setLoadingDocs(false);
                setLoadingError(true);
            })

    }, [logger, userName]);


    const [errorTypesByReviewLevel, setErrorTypesByReviewLevel] = useState<any>([]);


    const [showView, setShowView] = useState(false);





    return (
        <div>

            <Container fluid>
                <Row>
                    <Col className="mt-3 mx-5">
                        <h2 className="mb-2">Exam Entry</h2>


                        {/* <pre>ssn: {ssn}</pre> */}
                        {/* <div>no chosen doc? {JSON.stringify(chosenDocument, null, 4)}</div> */}
                        {/* <pre>{JSON.stringify(foundDocs, null, 4)}</pre> */}
                        {/* <button onClick={getErrorTypes}>test getErrorTypes</button> */}


                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="home" title="Enter Docs">

                                <Row>
                                    <Col lg={4}>
                                        {loadingDocs && <div>Loading docs...</div>}
                                        {!loadingDocs && !loadingError && docsLoaded &&
                                            <>

                                                <Row>
                                                    <Col>
                                                        <Pagination
                                                            postsPerPage={postsPerPage}
                                                            totalPosts={examDocs.length}
                                                            paginate={paginate}
                                                            currentPage={currentPage}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <SelectPageCount setPostsPerPage={setPostsPerPage}/>
                                                            <span className="me-2 text-muted form-text">results per page</span>
                                                    </Col>
                                                </Row>


                                                {loadingCurrentPage ?
                                                    <div className="mb-5">
                                                        <LoadingSpinner />
                                                    </div>
                                                    :
                                                    <>
                                                        {showView &&
                                                            <DocumentQueue
                                                                examDocs={currentResults}
                                                                chosenDocument={chosenDocument}
                                                                selectDocument={selectDocument}
                                                            />
                                                        }
                                                    </>
                                                }

                                                <Row>
                                                    <Col className="mt-3">
                                                        <Pagination
                                                            postsPerPage={postsPerPage}
                                                            totalPosts={examDocs.length}
                                                            paginate={paginate}
                                                            currentPage={currentPage}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </Col>
                                    <Col lg={5}>

                                        {holdingDoc && <div className="p-5 lightGreyBadge">
                                            <LoadingSpinner text="Holding document" /></div>}

                                        {discardingDoc && <div className="p-5 lightGreyBadge"><LoadingSpinner text="Discarding document" /></div>}

                                        {savingDoc && <div className="p-5 bg-dark-blue"><LoadingSpinner text="Saving document" spinnerColor="white" textColor="text-white" /></div>}

                                        {!holdingDoc && !discardingDoc && !savingDoc &&
                                            <DataEntrySection
                                                updateSSN={updateSSN}
                                                ssnIsValid={ssnIsValid}
                                                checkSSN={() => checkSSN()}
                                                checkingSSN={checkingSSN}
                                                ssnCheckButtonClicked={ssnCheckButtonClicked}
                                                isApplicantDataLoaded={isApplicantDataLoaded}
                                                loadingApplicantData={loadingApplicantData}

                                                applicantData={applicantData}

                                                chosenDocument={chosenDocument}
                                                searchDocumentAndSelectExamType={searchDocumentAndSelectExamType}
                                                showExamInput={showExamInput}
                                                examSearchResults={examSearchResults}
                                                goToSignatureReviewButtons={() => goToSignatureReviewButtons()}
                                                showWholeDocument={showWholeDocument}
                                                setShowWholeDocument={setShowWholeDocument}
                                            />
                                        }
                                    </Col>

                                    <Col>
                                        <div>
                                            {showWholeDocument &&
                                                <>
                                                    <SignatureQuestions examEntryInput={examEntryInput} errorTypesByReviewLevel={errorTypesByReviewLevel} />

                                                    <div>
                                                        {showSignatureReviewBtns &&
                                                            <div className="mt-2">
                                                                <SignatureReviewBtn shouldAutoFocus={showSignatureReviewBtns} onClick={() => saveDoc()} disabled={errorTypesByReviewLevel.length < 1} />
                                                                <button className="btn btn-outline-primary ms-1" onClick={holdDoc} disabled={errorTypesByReviewLevel.length < 1}>Hold</button>
                                                                <button className="btn btn-outline-primary ms-1" onClick={discardDoc} disabled={errorTypesByReviewLevel.length < 1}>Discard</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row>

                            </Tab>
                            <Tab eventKey="profile" title="History" disabled>
                                <EntryHistoryTable examDocs={examDocs} />
                            </Tab>

                            <Tab eventKey="held" title="Held" disabled>
                                <EntryHistoryTable examDocs={examHeldDocs} />
                            </Tab>

                            <Tab eventKey="discarded" title="Discarded" disabled>
                                <EntryHistoryTable examDocs={examDiscardedDocs} />
                            </Tab>

                        </Tabs>



                    </Col>
                </Row>
            </Container>
        </div>
    )
}
