import { ExamDocumentInterface } from "../helpers/interfaces";

export const examDocs: ExamDocumentInterface[] = [
    {
        id: "1",
        type: "A",
        fileSize: 152,
        pages: 14,
        examDate: new Date(2022, 4, 17),
        fileType: "pdf",
        received: "2:41 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "2",
        type: "M",
        fileSize: 144,
        pages: 13,
        examDate: undefined,
        fileType: "pdf",
        received: "2:10 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "3",
        type: "O",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "jpeg",
        received: "2:05 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "4",
        type: "M",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "pdf",
        received: "2:00 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "5",
        type: "M",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "pdf",
        received: "1:50 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "6",
        type: "M",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "pdf",
        received: "1:45 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "7",
        type: "M",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "pdf",
        received: "1:42 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "8",
        type: "M",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "pdf",
        received: "1:41 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "9",
        type: "M",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "pdf",
        received: "1:40 pm",
        receivedFrom: "Example Provider"
    }
]


export const exampleApplicants = [
    {
        ssn: "000-00-0000",
        last4ssn: "0000",
        age: "20",
        firstName: "Fred",
        lastName: "Miller",
        sex: "M",
        dob: "1/5/2002",
        exams: [
            {
                id: "1",
                examType: "Medical",
                examDate: undefined,
                reportedDate: new Date(2022, 4, 5).toISOString()
            },
            {
                id: "2",
                examType: "Optometric",
                examDate: new Date(2022, 4, 15).toLocaleDateString(),
                reportedDate: new Date(2022, 4, 15).toISOString()
            },
            {
                id: "3",
                examType: "Audiological",
                examDate: undefined,
                reportedDate: new Date(2022, 5, 28).toISOString()
            }
        ]
    },
    {
        ssn: "111-11-1111",
        last4ssn: "1111",
        age: "20",
        firstName: "Alexis",
        lastName: "Riviera",
        sex: "F",
        dob: "1/1/2002",
        exams: [
            {
                id: "1",
                examType: "Medical",
                examDate: undefined,
                reportedDate: new Date(2022, 4, 5).toISOString()
            },
            {
                id: "2",
                examType: "Optometric",
                examDate: new Date(2022, 4, 15).toLocaleDateString(),
                reportedDate: new Date(2022, 4, 15).toISOString()
            },
            {
                id: "3",
                examType: "Audiological",
                examDate: undefined,
                reportedDate: new Date(2022, 5, 28).toISOString()
            }
        ]
    },
    {
        ssn: "222-22-2222",
        last4ssn: "2222",
        age: "20",
        firstName: "John",
        lastName: "Simpson",
        sex: "M",
        dob: "1/8/2002",
        exams: [
            {
                id: "1",
                examType: "Medical",
                examDate: undefined,
                reportedDate: new Date(2022, 4, 5).toISOString()
            },
            {
                id: "2",
                examType: "Optometric",
                examDate: new Date(2022, 4, 15).toLocaleDateString(),
                reportedDate: new Date(2022, 4, 15).toISOString()
            },
            {
                id: "3",
                examType: "Audiological",
                examDate: undefined,
                reportedDate: new Date(2022, 5, 28).toISOString()
            }
        ]
    }
]

export const applicant = {
    ssn: "000-00-0000",
    last4ssn: "1111",
    age: "20",
    sex: "M",
    dob: "1/1/2002",
    exams: [
        {
            id: "1",
            examType: "Medical",
            examDate: undefined,
            reportedDate: new Date(2022, 4, 5).toISOString()
        },
        {
            id: "2",
            examType: "Optometric",
            examDate: new Date(2022, 4, 15).toLocaleDateString(),
            reportedDate: new Date(2022, 4, 15).toISOString()
        },
        {
            id: "3",
            examType: "Audiological",
            examDate: undefined,
            reportedDate: new Date(2022, 5, 28).toISOString()
        }
    ]
}


export const exampleExamDocs = [
    {
        id: "1",
        name: "Medical",
        reportedDate: new Date(2022, 4, 17),
        examDate: new Date(2022, 4, 17),
    },
    {
        id: "2",
        name: "Optometric",
        reportedDate: new Date(2022, 4, 17),
        examDate: new Date(2022, 4, 17)
    },
    {
        id: "3",
        name: "Audiological",
        reportedDate: new Date(2022, 4, 17),
        examDate: new Date(2022, 4, 17)
    },
]




export const examDiscardedDocs: ExamDocumentInterface[] = [
    {
        id: "1",
        type: "A",
        fileSize: 152,
        pages: 14,
        examDate: new Date(2022, 4, 17),
        fileType: "pdf",
        received: "2:41 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "2",
        type: "M",
        fileSize: 144,
        pages: 13,
        examDate: undefined,
        fileType: "pdf",
        received: "2:10 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "3",
        type: "O",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "jpeg",
        received: "2:05 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "4",
        type: "O",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "jpeg",
        received: "2:05 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "5",
        type: "O",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "jpeg",
        received: "2:05 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "6",
        type: "O",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "jpeg",
        received: "2:05 pm",
        receivedFrom: "Example Provider"
    }
]

export const examHeldDocs: ExamDocumentInterface[] = [
    {
        id: "1",
        type: "A",
        fileSize: 152,
        pages: 14,
        examDate: new Date(2022, 4, 17),
        fileType: "pdf",
        received: "2:41 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "2",
        type: "M",
        fileSize: 144,
        pages: 13,
        examDate: undefined,
        fileType: "pdf",
        received: "2:10 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "3",
        type: "O",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "jpeg",
        received: "2:05 pm",
        receivedFrom: "Example Provider"
    },
    {
        id: "4",
        type: "O",
        fileSize: 151,
        pages: 14,
        examDate: new Date(2022, 4, 13),
        fileType: "jpeg",
        received: "2:05 pm",
        receivedFrom: "Example Provider"
    }
]

export const signaturesToLookForList = [
    {
        docType: "M",
        questions: [
            {
                id: "1",
                question: "Has signature on page 4"
            }, {
                id: "2",
                question: "Has signature on page 6"
            }, {
                id: "3",
                question: "Has signature on page 7"
            }, {
                id: "4",
                question: "Has signature on page 9"
            }
        ]
    },
    {
        docType: "O",
        questions: [
            {
                id: "1",
                question: "Has signature for page 2"
            },
        ]
    },
    {
        docType: "A",
        questions: [
            {
                id: "1",
                question: "Has signature for page 1"
            },
            {
                id: "2",
                question: "Has signature for page 2"
            },
        ]
    },
    {
        docType: "H",
        questions: [
            {
                id: "1",
                question: "Has signature for page 1"
            },
            {
                id: "2",
                question: "Has signature for page 2"
            },
        ]
    }
];

export const validSsnList = ["000-00-0000", "111-11-1111", "222-22-2222"];