import { useEffect, useRef } from 'react';

interface Props {
    shouldAutoFocus: boolean;
    onClick: () => void;
    disabled: boolean;
}

export default function SignatureReviewBtn({ shouldAutoFocus, onClick, disabled }: Props) {

    const btnRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (shouldAutoFocus) {
            btnRef.current?.focus();
        }

    }, [shouldAutoFocus])

    return (
        <button
            ref={btnRef}
            className="btn btn-primary"
            id="save-as-is-btn"
            data-testid="save-as-is-btn"
            onClick={onClick}
            disabled={disabled}
        >Save as is
        </button>
    )
}