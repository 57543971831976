// import "./Pagination.css";

import NextPageButton from "../NextPageButton/NextPageButton";
import PageButton from "../PageButton/PageButton";

interface Props {
    postsPerPage: number;
    totalPosts: number;
    paginate: (pageNumber: number) => void;
    currentPage: number;
}

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }: Props) => {


    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    const isNotOnLastPage = currentPage < pageNumbers.length;


    const goToNextPage = () => {
        let nextPageNumber = currentPage + 1;
        if (isNotOnLastPage) {
            paginate(nextPageNumber);
        }
    }

    return (
        <>
            <nav>
                <ul className="pagination">
                    {pageNumbers.map(pageNumber => (
                        <PageButton key={pageNumber} pageNumber={pageNumber} currentPage={currentPage} paginate={paginate} />
                    ))}

                    <NextPageButton goToNextPage={goToNextPage} />
                </ul>
            </nav>
        </>
    )
}

export default Pagination;