import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap'
import { signaturesToLookForList } from '../../data/data';
import SignatureQuestionInput from '../SignatureQuestionInput/SignatureQuestionInput';
// import API from '../../utils/API';

interface Props {
    examEntryInput: string;
    errorTypesByReviewLevel: any;
}

export default function SignatureQuestions({ examEntryInput, errorTypesByReviewLevel }: Props) {

    // interface SignatureQuestion {
    //     id: string;
    //     question: string;
    // }

    // interface SignatureToLookForInterface {
    //     docType: string;
    //     questions: SignatureQuestion[];
    // }

    // interface QuestionObject {
    //     id: string;
    //     question: string;
    // }

    // const [selectedSignatureQuestion, setSelectedSignatureQuestion] = useState<QuestionObject>({
    //     id: "",
    //     question: ""
    // });

    // const [selectedDocTypeIndex, setSelectedDocTypeIndex] = useState(0);
    const [selectedSignatureIndex, setSelectedSignatureIndex] = useState(0);
    const [totalNumberOfQuestions, setTotalNumberOfQuestions] = useState(0);

    const changeSelectedSignatureIndex = (newIndex: number) => {
        setSelectedSignatureIndex(newIndex);
    }


    // const selectSignatureQuestion = (question: QuestionObject) => {
    //     setSelectedSignatureQuestion(question);
    // }


    // const holdDocument = () => {
    //     API.holdDocument()
    //         .then(res => {
    //             console.log(res);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }


    useEffect(() => {

        let index = signaturesToLookForList.findIndex(item => item.docType === examEntryInput);
        // set first question to the index
        // let firstQuestion = signaturesToLookForList[index].questions[0];
        let totalNumberOfQuestions = signaturesToLookForList[index].questions.length;
        // console.log("TOTAL QUESTIONS FOR  SELECTED DOCUMENT: ", totalNumberOfQuestions);
        // console.log("FIRST QUESTION: ", firstQuestion);
        // setSelectedSignatureQuestion(firstQuestion);
        setTotalNumberOfQuestions(totalNumberOfQuestions);
        setSelectedSignatureIndex(index);
        // setSelectedDocTypeIndex(index);

    }, [examEntryInput])


    return (
        <div>
            <Row style={{ marginTop: "600px" }}>
                <Col>
                    <div>
                        <strong>Signature Check(s):</strong> {examEntryInput === "M" ? "Medical" : examEntryInput === "A" ? "Audiological" : examEntryInput === "O" ? "Optometric" : ""}
                    </div>

                    <div className="text-muted form-text mb-2 mt-0">Press "y" (yes) or "n" (no)</div>

                    <div>
                        {errorTypesByReviewLevel.length > 0 ?
                            <>
                                {errorTypesByReviewLevel.map((item: any, index: number) => (
                                    <div key={item.id} className="mb-2 lightCard">
                                        <SignatureQuestionInput
                                            questionIndex={index}
                                            question={item.requirement}
                                            description={item.description}
                                            selectedSignatureIndex={selectedSignatureIndex}
                                            changeSelectedSignatureIndex={changeSelectedSignatureIndex}
                                            totalNumberOfQuestions={totalNumberOfQuestions}
                                        />
                                    </div>
                                ))}
                            </>
                            :
                            <div className="text-muted lightGreyBadge p-2 rounded">No error types to display</div>
                        }
                    </div>

                </Col>
            </Row>
        </div>
    )
}