import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type Props = {
    applicantData: any;
}

export default function ApplicantInfo({ applicantData }: Props) {

    return (
        <div className="mb-2" data-testid="applicantInfoDiv">
            <h4 data-testid="applicantNameHeader">Applicant: {applicantData.firstName} {applicantData.lastName}</h4>

            <Row>
                <Col>
                    <div data-testid="last4"><strong>Last 4:</strong> {applicantData.last4ssn}</div>
                    <div data-testid="age"><strong>Age:</strong> {applicantData.age}</div>
                </Col>
                <Col>
                    <div data-testid="sex"><strong>Sex:</strong> {applicantData.sex}</div>
                    <div data-testid="dob"><strong>DOB:</strong> {applicantData.dob}</div>
                </Col>
            </Row>

        </div>
    )
}