import axios from 'axios';



const API = {
    getQueue: function () {
        return new Promise((resolve) => setTimeout(resolve, 400))
    },
    getApplicantData: function () {
        return new Promise((resolve) => setTimeout(resolve, 400))
    },

    getErrorTypesByReviewLevel: function (backendApiUrl: string, reviewLevel: string, docType: string) {
        return axios.get(`${backendApiUrl}/api/error-logs/typesbyreviewlevel`, { params: { reviewLevel, docType } })
    },

    holdDocument: function () {
        return new Promise((resolve) => setTimeout(resolve, 400))
    },

    discardDocument: function () {
        return new Promise((resolve) => setTimeout(resolve, 400))
    },

    getFiles: function (backendApiUrl: string, applicantId: string) {
        return axios.get(`${backendApiUrl}/apix/basic/Files/${applicantId}/Label`)
    },


    // ==============================
    // Azure functions
    // ==============================

    getSettings: function () {
        return axios.get(`/api/settings`);
    },
    getEnvironment: function () {
        return axios.get(`/api/environment`);
    }

}

export default API;