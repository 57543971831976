import { useEffect, useRef } from 'react';
import WebViewer from '@pdftron/webviewer';

interface Props {
    show: boolean;
}

export default function ViewWholeDocument({ show }: Props) {


    const viewer = useRef<HTMLDivElement>(null);


    useEffect(() => {
        console.log("creating webviewer instance for view whole document...")
        WebViewer(
            {
                path: '/lib',
                // licenseKey: process.env.REACT_APP_WEBVIEWER_LICENSE_KEY,
                initialDoc: '/files/PDFTRON_about.pdf'
                // initialDoc: 'https://pdftron.s3.amazonaws.com/downloads/pl.webviewer-demo.pdf'
            },
            viewer.current as HTMLDivElement,
        ).then((instance) => {
            console.log("whole document instance created...")

            instance.UI.disableElements(['toolsHeader']);
            // instance.UI.closeElements(['toolsHeader']);

        })
            .catch(err => {
                console.log(err)
            });
    }, []);




    return (
        <div>

            {/* <div style={{ display: show ? "block" : "none" }} data-testid="viewWholeDocument"> */}
            <div data-testid="viewWholeDocument">
                <div className="App">
                    <div className="webviewer" ref={viewer}></div>
                </div>
            </div>

        </div>
    )
}