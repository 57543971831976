import React from 'react'
import { ExamDocumentInterface } from '../../helpers/interfaces'

type Props = {
    doc: ExamDocumentInterface
}

export default function EntryHistoryTableRow({ doc }: Props) {


    const reportDateFormatted = "5/10/2022"

    // const isReportDateInFuture = false;


    const date = new Date(2022, 4, 18).toLocaleDateString();

    const mutedText: string = "form-text text-muted";

    return (
        <tr>
            <td>{doc.type}</td>
            <td>{doc.receivedFrom}</td>
            <td>{reportDateFormatted}</td>
            <td>
                {doc.examDate ?
                    <div>
                        <div>{doc.examDate.toString()}</div>
                        <div className={mutedText}>via check-in</div>
                    </div>
                    :
                    <div>
                        <div>5/13/2022</div>
                        <div  className={mutedText}>via manual entry</div>
                    </div>
                }
            </td>
            {/* <td><Button disabled={isReportDateInFuture}>assign</Button></td> */}
            <td>{date}</td>
        </tr>
    )
}