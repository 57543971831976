import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

type Props = {
    questionIndex: number;
    question: string;
    description: string;
    selectedSignatureIndex: number;
    changeSelectedSignatureIndex: (newIndex: number) => void;
    totalNumberOfQuestions: number
}

export default function SignatureQuestionInput({
    questionIndex,
    question,
    description,
    selectedSignatureIndex,
    changeSelectedSignatureIndex,
    totalNumberOfQuestions
}: Props) {


    const inputRef = useRef<HTMLInputElement>(null);
    const shouldQuestionBeFocused = questionIndex === selectedSignatureIndex;
    const [inputValue, setInputValue] = useState("");

    const focusOnSaveAsIsBtn = () => {
        let saveAsIsBtn = document!.getElementById("save-as-is-btn") as HTMLInputElement;
        if (saveAsIsBtn !== null) {
            saveAsIsBtn.focus();
        }
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        let key = e.key;
        if (key === "Backspace") {
            setInputValue("");
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;

        // console.log(`questionIndex: ${questionIndex}, totalNumberOfQuestions: ${totalNumberOfQuestions}`);

        if (value === "y" || value === "n" || value === "Y" || value === "N") {
            setInputValue(value.toUpperCase());

            // go to next signature question
            changeSelectedSignatureIndex(questionIndex + 1);

            // check if question is last in array. If so, next input should be the buttons below (save as is, split, discard, hold)
            let onLastQuestion = questionIndex + 1 === totalNumberOfQuestions;
            if (onLastQuestion) {
                focusOnSaveAsIsBtn();
            }

        }
    }

    const imageSrc = "https://civcdn.dodmets.com/css/bootstrap-icons/1.8.3/icons/info-circle.svg";

    useEffect(() => {

        if (shouldQuestionBeFocused) {
            if (inputRef.current) {
                inputRef.current.focus();
            }

            if (questionIndex + 1 === totalNumberOfQuestions) {
                let nextSigBtn = document!.getElementById(`signatureCheckbox-${questionIndex + 1}`) as HTMLInputElement;
                if (nextSigBtn !== null) {
                    nextSigBtn.focus();
                }
            }
        } else {
            if (inputRef.current) {
                inputRef.current.value = "";
            }
        }

    }, [shouldQuestionBeFocused, questionIndex, totalNumberOfQuestions]);


    return (
        <div>

            <Row>
                <Col lg={3}>
                    <input className="form-control form-control-sm"
                        value={inputValue}
                        id={`signatureCheckbox-${questionIndex}`}
                        data-testid={`signatureCheckbox-${questionIndex}`}
                        onChange={e => handleInputChange(e)}
                        onKeyDown={e => handleKeyPress(e)}
                        maxLength={1}
                        ref={inputRef}
                    />
                </Col>

                <Col>
                    <label className="form-check-label form-text text-dark" htmlFor={`signatureCheckbox-${questionIndex}`}>
                        {inputValue === "Y" && <span>✔</span>}  {inputValue === "N" && <span>❌</span>} {question}? <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id='tooltip-top'>
                                        {description}
                                    </Tooltip>
                                }
                            >
                                <img src={imageSrc} alt="circle" />
                            </OverlayTrigger>
                        </span>
                    </label>
                </Col>
            </Row>

        </div>
    )
}