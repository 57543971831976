import { useEffect, useRef } from 'react';
import { ExamDocumentInterface } from '../../helpers/interfaces'
import { pdfIcon, jpgIcon } from '../../helpers/svgIcons';
import { SelectDocumentFunction } from '../../pages/ExamEntry';
import WebViewer from '@pdftron/webviewer';


interface Props {
    doc: ExamDocumentInterface;
    selectDocument: SelectDocumentFunction;
    chosenDocument: ExamDocumentInterface;
}

export default function ExamDocument({ doc, selectDocument, chosenDocument }: Props) {

    const { fileType } = doc;
    const isSelected = chosenDocument === doc;
    const selectedDocStyle = "card mb-2 lightCardNoHover pb-5 shadow-sm border-primary";
    const nonSelectedDocStyle = "card mb-2 lightCard pb-5 shadow-sm pointer";


    const iconToShow = fileType === "pdf" ? pdfIcon : jpgIcon;


    const viewer = useRef<HTMLDivElement>(null);
    const thumbnailRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        console.log("creating webviewer instance...")
        WebViewer(
            {
                path: '/lib',
                // licenseKey: process.env.REACT_APP_WEBVIEWER_LICENSE_KEY,
                initialDoc: '/files/PDFTRON_about.pdf'
            },
            viewer.current as HTMLDivElement,
        ).then((instance) => {
            console.log("pdftron instance loaded...")

            const { documentViewer } = instance.Core;

            documentViewer.addEventListener('documentLoaded', () => {
                const doc = documentViewer.getDocument();

                const pageNumber = 1;

                doc.loadCanvasAsync(({
                    pageNumber,
                    zoom: 2, // render at twice the resolution
                    drawComplete: async (thumbnail) => {
                        // optionally comment out "drawAnnotations" below to exclude annotations
                        // await instance.Core.documentViewer.getAnnotationManager().drawAnnotations(pageNumber, thumbnail);
                        // thumbnail is a HTMLCanvasElement or HTMLImageElement
                        // console.log(thumbnail);

                        let canvas = thumbnail;
                        canvas.style = "width: 310px; height: 400px";

                        thumbnailRef.current as HTMLDivElement
                        thumbnailRef.current?.appendChild(canvas);
                    }
                }));


            });

        }).catch(err => console.log("pdftron err: ", err));
    }, []);



    return (
        <div
            key={doc.id}
            data-testid="examDocumentDiv"
            className={isSelected ? selectedDocStyle : nonSelectedDocStyle}
            onClick={() => selectDocument(doc)}
        >

            <div className="text-muted form-text">{iconToShow} {fileType} | {doc.received} | Filesize: {doc.fileSize} KB | <strong data-testid="docPages">{doc.pages} pages</strong></div>

            <div style={{ height: "400px" }}>
                <div className="thumbnail" ref={thumbnailRef}></div>
            </div>

            <div style={{ display: "none" }}>
                <div className="App">
                    <div className="webviewer" ref={viewer}></div>
                </div>
            </div>

        </div>
    )
}