export function formatSSN(value: string) {
    // console.log("ssn value is: ", value);
    if (!value) return value;
    const ssn = value.replace(/[^\d]/g, '');
    const ssnLength = ssn.length;

    if (ssnLength < 4) return ssn;

    if (ssnLength < 6) {
        return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }

    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 8)}`;
}



export const getMonth = (date: Date) => date.getMonth() + 1;
export const getDate = (date: Date) => date.getDate();
export const getYear = (date: Date) => date.getFullYear();
export const formatDate = (month: number, day: number, year: number) => `${month}/${day}/${year}`;