import React, { useEffect, useRef } from 'react';
import { FormControl, InputGroup, Button } from 'react-bootstrap';
import { FormatSSNFunction, UpdateSSNFunction } from '../../helpers/interfaces';

interface Props {
  checkSSN: () => void;
  formatSSN: FormatSSNFunction;
  updateSSN: UpdateSSNFunction;
  disabled: boolean;
}

export default function CheckSsnInput({ checkSSN, formatSSN, updateSSN, disabled }: Props) {


  const ssnRef = useRef<HTMLInputElement>(null);

  function ssnFormatter(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key !== 'Enter') {
      const inputField = ssnRef;
      if (inputField != null) {
        if (inputField.current) {
          if (inputField.current.value) {
            // console.log("input value is: ", inputField.current.value);
            // console.log("............")
            const formattedInputValue = formatSSN(inputField.current.value);
            inputField.current.value = formattedInputValue;
          }
        }
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      checkSSN();
    }

  }

 

  useEffect(() => {
    if (disabled === false) {
      ssnRef.current?.focus();
    }
  }, [disabled])



  return (
    <InputGroup className="mb-3">
      <FormControl
        id="checkSsnInput"
        data-testid="checkSsnInput"
        placeholder=""
        aria-label="Enter ssn"
        aria-describedby="basic-addon2"
        autoFocus={true}
        name="ssn"
        className="form-control form-control-sm"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateSSN(e.target.value)}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => ssnFormatter(e)}
        ref={ssnRef}
        maxLength={30}
        disabled={disabled}
      />
      <Button
        id="basic-addon2"
        className="btn bg-dark-blue"
        onClick={() => checkSSN()}
        disabled={disabled}>
        check ssn
      </Button>
    </InputGroup>
  )
}