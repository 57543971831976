import React from 'react'
import Table from 'react-bootstrap/Table';
// import Button from 'react-bootstrap/Button';
import { ExamDocumentInterface } from '../../helpers/interfaces';
// import { Link } from 'react-router-dom'
import EntryHistoryTableRow from './EntryHistoryTableRow';


interface Props {
    examDocs: ExamDocumentInterface[];
}

export default function ExamTable({ examDocs }: Props) {



    // const today = new Date();

    // const hasExamDateHappened = exam.examDate.

    // const reportDate = new Date(exam.reportedDate);
    // const mm = reportDate.getMonth() + 1;
    // const dd = reportDate.getDate();
    // const yyyy = reportDate.getFullYear();

    // const reportDateFormatted = `${mm}/${dd}/${yyyy}`;

    // const isReportDateInFuture = reportDate > today;


    return (
        <div>
            <Table bordered className="text-dark rounded" style={{ tableLayout: "fixed", width: "100%" }}>
                <thead>
                    <tr>
                        <th>Exam</th>
                        <th>Received From</th>
                        <th>Reported Date</th>
                        <th>Exam Date</th>
                        <th>Entered on</th>
                    </tr>
                </thead>
                <tbody>
                    {examDocs.map(doc => (
                        <EntryHistoryTableRow key={doc.id} doc={doc} />
                    ))}
                </tbody>
            </Table>

        </div>
    )
}