import React from 'react'

interface Props {
    pageNumber: number;
    currentPage: number;
    paginate: (pageNumber: number) => void;
}

export default function PageButton({ pageNumber, currentPage, paginate }: Props) {
    return (
        <li className="page-item" style={{ cursor: "pointer" }}>
            <div onClick={() => paginate(pageNumber)} className={currentPage === pageNumber ? "page-link pageLinkClicked  bg-primary text-white border border-1" : "page-link pageLinkUnclicked  border border-1"}>
                {pageNumber}
            </div>
        </li>
    )
}