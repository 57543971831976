import Table from 'react-bootstrap/Table';
// import { ExamInterface } from '../../helpers/interfaces';


interface Props {
  exam: any;
}

export default function SearchResultsTable({ exam }: Props) {

  // console.log(exam);

  const getMonth = (date: Date) => date.getMonth() + 1;
  const getDate = (date: Date) => date.getDate();
  const getYear = (date: Date) => date.getFullYear();
  const formatDate = (month: number, day: number, year: number) => `${month}/${day}/${year}`;

  const reportDate = exam.reportedDate;
  const mm = getMonth(reportDate);
  const dd = getDate(reportDate);
  const yyyy = getYear(reportDate);

  const reportDateFormatted = formatDate(mm, dd, yyyy);
  // const isReportDateInFuture = reportDate > today;
  const isReportDateInFuture = false;


  const examDate = exam.examDate;
  const m = getMonth(examDate);
  const d = getDate(examDate)
  const y = getYear(examDate);

  const examDateFormatted = formatDate(m, d, y)




  return (
    <div>
      <Table bordered className={isReportDateInFuture ? "text-muted rounded" : "text-dark rounded"} style={{ tableLayout: "fixed", width: "100%" }} data-testid="searchResultsTable">
        <thead>
          <tr>
            <th data-testid="exam">Exam</th>
            <th data-testid="reported-date">Reported Date</th>
            <th data-testid="exam-date">Exam Date</th>
          </tr>
        </thead>
        <tbody className={isReportDateInFuture ? "text-muted" : "text-dark"}>
          <tr>
            <td>{exam.name}</td>
            <td>{reportDateFormatted}</td>
            <td>
              {exam.examDate ?
                <div>
                  <div>{examDateFormatted}</div>
                  <div className="form-text text-muted">via check-in</div>
                </div>
                :
                <>
                  {examDateFormatted}
                </>
              }
            </td>
          </tr>

        </tbody>
      </Table>

    </div>
  )
}